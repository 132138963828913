<template>
    <div>
        <q-score-card
            key="QScoreCardStandingLHRequests"
            title="Standing Lighthouse Requests"
            api-endpoint="leads_requested/lh"
            drill-down-route=""
            card-style="leads"
            :agent-code="agentCode"
            :filters="filters"
        />
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue"

export default {
    name: "QScoreCardStandingLHRequests",
    props: {
        agentCode: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
    },
    components: {
        QScoreCard,
    },
}
</script>
<style scoped></style>
