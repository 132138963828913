<template>
    <v-card class="pa-0 elevation-0 scorecard-card">
        <div class="pa-1 pt-2 title-head" :class="cardStyle">
            {{ title }}
        </div>
        <div class="pa-3 body-stat" :class="cardStyle">
            <div class="stat-detail">
                <div v-if="loading" class="stat-number">
                    <v-progress-circular indeterminate />
                </div>
				<template v-else>
					<div class="score-card-label">
						{{ prevWeek.label }}
					</div>
					<div class="stat-number pt-4">
						<span :class="statNumberClass">
							{{ value }}
						</span>
					</div>
				</template>
            </div>
        </div>

        <div class="footer-expand">
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header class="expansion-label"></v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="stat-notes">
                            <slot></slot>
                        </div>
                        <v-divider class="py-3" />
                        <v-btn v-if="drillDownRoute" block color="q_green_1" @click="gotoDrillDown">
                            <v-icon v-if="!hideButtonIcon" left>fas fa-chart-pie</v-icon>
                            {{ drillDownLabel }}
                        </v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </v-card>
</template>

<script>
const getDate = () => {
	const date = new Date()
	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()
	return `${year}-${month}-${day}`
}

export default {
    props: {
		apiData: {
			type: Object,
			default: () => ({})
		},
        agentCode: {
            type: String,
            default: null
        },
        apiEndpoint: {
            type: String,
            default: 'onboarding-recruiting-personal'
        },
        drillDownRoute: {
            type: String,
            default: 'Drilldown_QuilityOnboardingPortalRecruitingStatus',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        statusKey: {
            type: String,
            default: 'overall'
        },
        title: {
            type: String,
            default: 'Title'
        },
        hideButtonIcon: {
            type: Boolean,
            default: false
        },
        drillDownLabel: {
            type: String,
            default: 'Agent Breakdown'
        },
		cardStyle: {
            type: String,
            default: 'recruiting'
        },
    },
    computed: {
		value () {
			return this.apiData[this.statusKey]
		},
		prevWeek () {
			return this.getAvailableBusinessDates('week', false, false)[0]
		},
		level () {
            switch (this.apiEndpoint) {
                case 'onboarding-recruiting-personal':
                    return 'personal'
                case 'onboarding-recruiting-baseshop':
                    return 'baseshop'
                case 'onboarding-recruiting-totalagency':
                    return 'totalagency'
                default:
                    return 'personal'
            }
		},
		statNumberClass () {
			return this.value > 999999 ? 'large' : ''
		},
    },
    methods: {
        gotoDrillDown () {
            const params = {
                agent_code: this.agentCode,
            }
            this.$router.push({
                name: this.drillDownRoute,
                params,
                query: {
                    statusKey: this.statusKey,
                    title: `${this.title} Drilldown`,
                    agent_code: this.agentCode,
                    date: getDate(),
                    level: this.level,
                },
            })
        },
    },
}
</script>
