<!-- Use as template for other scorecards //-->
<template>
    <div>
        <!--
        Make sure the key is unique.
        api endpoint will be the part after "/api/private/scorecards/..."
        drill down rout is the "NAME" of the route in the router file.
        The style is the css class name for the section of scorecards to customize colors
        agent code will get passed from the view. Should come from url string or default to the current logged in user. 
        filters will come from the view as well... may or may not be used. 
        format can be "Currency" "Percentage" or don't pass anything for int. 
            //-->
        <q-score-card key="QScoreCardHqLogins" title="HQ Sign Ins" api-endpoint="hq-logins" drill-downLabel="Agent Breakdown" drill-down-route="Drilldown_HQ_Logins" card-style="hq-stats" :agent-code="agentCode" :filters="filters">
            Note: Shows number of unique agents that signed into HQ for the time period.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardHqLogins",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}
</script>
<style scoped>
</style>