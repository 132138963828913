<template>
	<v-card>
		<v-card-title class="q-display-3 mt-0 mb-0 text-no-wrap q_green_1 white--text">Onboarding Portal Stats</v-card-title>
		<v-card-text>
			<v-subheader class="mb-n9 mt-3 font-weight-bold q-display-1">Registrant by license status</v-subheader>
			<v-row>
				<v-col cols="12" md="6" lg="3">
					<q-onboarding-recruiting-status-score-card
						key="onboarding-recruiting-status-unlicensed"
						:api-data="apiData"
						:agent-code="agentCode"
						:api-endpoint="apiEndpoint"
						:loading="loading"
						status-key="license_status_unlicensed"
						title="Passed Exam Status"
					/>
				</v-col>
				<v-col cols="12" md="6" lg="3">
					<q-onboarding-recruiting-status-score-card
						key="onboarding-recruiting-status-licensed"
						:api-data="apiData"
						:agent-code="agentCode"
						:api-endpoint="apiEndpoint"
						:loading="loading"
						status-key="license_status_licensed"
						title="Licensed Status"
					/>
				</v-col>
			</v-row>

			<v-divider class="mt-3"/>

			<v-subheader class="mb-n9 mt-3 font-weight-bold q-display-1">Completed agents</v-subheader>
			<v-row>
				<v-col class="px-3" cols="12" md="6" lg="3">
					<q-onboarding-recruiting-status-score-card
						key="onboarding-recruiting-converted-converted"
						:api-data="apiData"
						:agent-code="agentCode"
						:api-endpoint="apiEndpoint"
						:loading="loading"
						status-key="completed_converted"
						title="Converted Complete"
					/>
				</v-col>
				<v-col class="px-3" cols="12" md="6" lg="3">
					<q-onboarding-recruiting-status-score-card
						key="onboarding-recruiting-converted-licensed"
						:api-data="apiData"
						:agent-code="agentCode"
						:api-endpoint="apiEndpoint"
						:loading="loading"
						status-key="completed_licensed"
						title="Licensed Complete"
					/>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import QOnboardingRecruitingStatusScoreCard from '@/components/stats/scorecards/QOnboardingRecruitingStatusScoreCard.vue'

const getDate = () => {
	const date = new Date()
	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()
	return `${year}-${month}-${day}`
}

export default {
	components: {
        QOnboardingRecruitingStatusScoreCard,
    },
    props: {
		agentCode: {
            type: String,
            default: null,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
		dataRequiresFilters: {
            // If set to true data load won't be called on mounted, will wait for filter values to load.
            // Could probably default to true but haven't checked with all the cards.
            type: Boolean,
            default: false,
        },
	},
    data () {
        return {
			apiData: {},
            loading: false,
        }
    },
    created () {
		if (!this.dataRequiresFilters) {
			this.loadData()
		}
    },
    computed: {
		apiEndpoint () {
            switch (this.filters.statType) {
                case 'PersonalProduction':
                    return 'onboarding-recruiting-personal'
                case 'Baseshop':
                    return 'onboarding-recruiting-baseshop'
                case 'TotalAgency':
                    return 'onboarding-recruiting-totalagency'
                default:
                    return 'onboarding-recruiting-personal'
            }
        },
    },
    watch: {
        agentCode () {
            this.apiData = {}
            this.loadData()
        },
        filters: {
            deep: true,
            handler () {
				this.apiData = {}
				this.loadData()
            },
        },
    },
    methods: {
        loadData: debounce(function () {
            this.loading = true
            const CancelRequestName = `getScoreCard_${this.apiEndpoint}`
            QuilityAPI.cancelQRequest(CancelRequestName)
            const params = {
                ...this.filters,
                date: getDate(),
            }
            QuilityAPI.getRequest(`/api/private/scorecards/${this.apiEndpoint}/${this.agentCode}`, params, CancelRequestName)
				.then((json) => {
					this.apiData = json
					this.loading = false
				})
                .catch((err) => {
					this.showError('There was a problem loading the scorecards data.<br>')
                    console.error(err)
                })
        }, 200),
    },
}
</script>
