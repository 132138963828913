<template>
    <div>
        <q-score-card key="QScoreCardBonusLeads" ref="card" title="Bonus Leads" api-endpoint="leads/bonus" drill-down-route="" card-style="leads" :agent-code="agentCode" :filters="filters" v-on:most_current="updateMostCurrent">
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardBonusLeads",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        updateMostCurrent: function(most_current) {
            this.$emit('most_current', most_current);
        }
    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
