<template>
    <v-container class="q-promotion-tracker-chart pa-5 q_border_left_1">
        <div style="border-bottom:#ccc thin solid" v-if="data.length > 0">
            <highcharts :options="apvChartOptions"></highcharts>
        </div>
        <v-container v-if="agentContractLevel >= 95">
            <v-row class="text-center ml-4 pt-4">
                <v-col cols="12" class="pt-0"><strong>Unique Writers - Goal: {{uwGoal}} </strong></v-col>
                <v-col cols="3" v-for="(uw, key) in uniqueWriters" :key="key">
                    <div class="elevation-2 unique-writers-stat green lighten-3">{{ uw[1] }}</div>
                    <span class="stat-label">{{ uw[0] }}</span>
                </v-col>
            </v-row>
            <!--
            <v-row class="text-center q-med-grey pt-4">
                <v-col cols="12"><strong>Current Status:</strong></v-col>
                <v-col cols="4" class="pa-0">
                    <span class="stat-label">Contract Level:</span><br><strong>{{promotionLevel.ContractLevel}}</strong>
                </v-col>
                <v-col cols="4" class="pa-0">
                    <span class="stat-label">MP:</span><br><strong>{{formatCurrency(promotionLevel.MP)}}</strong>
                </v-col>
                <v-col cols="4" class="pa-0">
                    <span class="stat-label">FE:</span><br><strong>{{formatCurrency(promotionLevel.FE)}}</strong>
                </v-col>
            </v-row>
            //-->
        </v-container>
        <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
    </v-container>
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
//import mockdata from '@/store/sample_data/MockDataPromotionTracker'
import theme from './Theme'
import User from '@/store/Models/User'
import PromotionLevels from '@/store/PromotionLevels.json'
import dataTransformer from '@/store/dataTransformer'

export default {
    name: "QPromotionTrackerChart",
    props: ['data', 'agentContractLevel', 'placedData'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var monthlyLabels = dataTransformer.getMonthlyLabels("PeriodMonth", "PeriodYear", this.data, 4)
        var apv = dataTransformer.monthlyData("apv", "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
        //var apps = dataTransformer.monthlyData("Apps", "PeriodMonth", "PeriodYear", this.data, 3);
        var nextP = g.nextPromotionLevel(g.agentContractLevel);
        var apvGoal = nextP.APV
        var minAPV = nextP.MinimumAPV
        var uwGoal = nextP.UniqueWriters
        var placed = dataTransformer.monthlyData("placedAmount", "PeriodMonth", "PeriodYear", this.placedData, monthlyLabels);
        return {
            uwGoal: uwGoal,
            apvChartOptions: {
                chart: {
                    type: 'column',
                    //plotShadow: true,
                    //plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    height: "50%"
                },
                title: {
                    text: 'APV & Placed',
                },
                xAxis: {
                    categories: monthlyLabels,
                },
                yAxis: [{
                    //currency axes
                    title: {
                        text: ''
                    },
                    labels: {
                        formatter: function(v) {
                            return g.formatNumber(this.value, '0,0a');
                        }
                    },
                    softMax: apvGoal,
                    maxPadding: .1,
                    plotLines: [
                        /*{
                                                label: {
                                                    text: "Submitted Goal",
                                                    className: 'secondary'
                                                },
                                                value: apvGoal,
                                                width: 3,
                                                color: "#4CAF50",
                                                zIndex: 4
                                            }*/
                        , {
                            label: {
                                text: "Placed Goal",
                                className: 'secondary'
                            },
                            value: minAPV,
                            width: 1,
                            color: '#4CAF50',
                            zIndex: 4,
                            className: 'secondary'
                        }
                    ]
                }],
                legend: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    pointFormatter: function(v) {
                        var value = null
                        if (this.series.name == "Unique Writers") {
                            value = this.y
                        } else {
                            value = g.formatCurrency(this.y)
                        }
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {

                },
                series: [{
                    name: 'APV',
                    data: apv,
                    stack: 'APV',
                    colorIndex: 0,
                }, {
                    name: 'Placed',
                    data: placed,
                    stack: 'Placed',
                    colorIndex: 4,
                }],
            }
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
        //Chart.
    },
    computed: {
        promotionLevel: function() {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (this.agentContractLevel == PromotionLevels[i].CommissionLevel) {
                    return PromotionLevels[i]
                }
            }
            return {};
        },
        uniqueWriters: function() {
            var monthlyLabels = dataTransformer.getMonthlyLabels("PeriodMonth", "PeriodYear", this.data, 4)
            return dataTransformer.monthlyData("unique_writers", "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
        }
    },
    methods: {
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        }
    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
