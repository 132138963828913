<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <q-comparison-chart v-if="!loading && stats.monthlyPlaced" label="<strong>Placed</strong>" format="currency" :current="parseFloat(stats.monthlyPlaced[stat_key])" :previous="parseFloat(stats.monthlyPlacedPrevious[stat_key])" :goal="goal" primary-color="#24CBE5" secondary-color="#d2e8ec" :previous-category="stats.monthlyPlacedPrevious.monthName" :current-category="stats.monthlyPlaced.monthName"></q-comparison-chart>
        <div class="stat pb-4" v-if="!loading && stats.monthlyPlaced">
            <q-pie-chart-carrier-placed :data="stats.monthlyPlaced.carriers" title="Placed By Carrier" :subtitle="stats.monthlyPlaced.monthName"></q-pie-chart-carrier-placed>
        </div>
    </div>
</template>
<script>
import QComparisonChart from '@/components/charts/QComparisonChart.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QPieChartCarrierPlaced from '@/components/charts/QPieChartCarrierPlaced.vue';

export default {
    name: "QStatsPlacedChart",
    props: ['agent', 'startDate', 'endDate', 'statType', 'timePeriod', 'goal'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            stats: {},
            loading: false
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        'stat_key': function() {
            if (this.statType == 'Baseshop') {
                return 'baseshop_apv'
            }
            if (this.statType == 'PersonalProduction') {
                return 'personal_apv'
            }
            if (this.statType == 'Master') {
                return 'apv'
            }
            if (this.agent.LeadershipLevel == "Agency Owner" || this.agent.LeadershipLevel == "Key Leader") {
                return 'baseshop_apv';
            }
            if (this.agent.IsAgencyOwner) {
                return 'apv';
            }
            return 'personal_apv';
        }
    },
    methods: {
        loadData: debounce(function() {
            if (typeof this.agent == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;

            var params = {
                type: this.statType,
                startDate: this.startDate,
                endDate: this.endDate,
                timePeriod: this.timePeriod
            }; //you can add "type" to get a certain type of stats - baseshop or total agency or production

            //gets the stats
            QuilityAPI.getPlacedStats(this.agent.AgentCode, params).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        }, 200),
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.loadData);
        },
        'startDate': function() {
            this.$nextTick(this.loadData);
        },
        'endDate': function() {
            this.$nextTick(this.loadData);
        },
        'timePeriod': function() {
            this.$nextTick(this.loadData);
        }
    },
    components: {
        QComparisonChart,
        QPieChartCarrierPlaced
    }
}

</script>
