<template>
    <highcharts :options="chartOptions"></highcharts>
    <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
import mockdata from '@/store/sample_data/MockDataHighcharts'
import dataTransformer from '@/store/dataTransformer'
import theme from './Theme'

export default {
    name: "QMonthlySalesChart",
    props: ['height', 'data', 'placedData', 'type'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var apv_key = "apv"
        var app_key = "apps"
        var placed_key = "placedAmount"
        if (this.type == 'PersonalProduction') {
            placed_key = "AgentPlacedAmount"
        }
        if (this.type == 'Baseshop') {
            placed_key = "BaseAgencyPlacedAmount"
        }
        var monthlyLabels = dataTransformer.getMonthlyLabels("PeriodMonth", "PeriodYear", this.data, 9)
        var apv = dataTransformer.monthlyData(apv_key, "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
        var apps = dataTransformer.monthlyData(app_key, "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
        var placed = dataTransformer.monthlyData(placed_key, "PeriodMonth", "PeriodYear", this.placedData, monthlyLabels);
        //var d2 = mockdata.dailyUniqueWriters();
        //var d3 = mockdata.dailyPlaced();
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    plotShadow: true,
                    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    zoomType: 'x',
                    height: this.height ? this.height : "30%"
                },
                title: {
                    text: '',
                },
                xAxis: {
                    //type: 'datetime',
                    categories: monthlyLabels
                },
                yAxis: [{
                    //currency axes
                    title: {
                        text: 'Submitted & Placed'
                    },
                    labels: {
                        formatter: function(v) {
                            return g.formatCurrency(this.value);
                        }
                    },
                    softMin: 0,
                    alignTicks: true,
                    allowDecimals: false
                }, {
                    //unique writes axis
                    title: {
                        text: 'Apps Submitted'
                    },
                    labels: {
                        formatter: function(v) {
                            return Math.round(this.value);
                        }
                    },
                    opposite: true,
                    softMin: 0,
                    alignTicks: true,
                    allowDecimals: false
                }],
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    //headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function(v) {
                        var value = null
                        if (this.series.name == "Unique Writers" || this.series.name == "Apps Submitted") {
                            value = this.y
                        } else {
                            value = g.formatCurrency(this.y)
                        }
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: .3,
                        borderWidth: 0
                    }
                },
                series: [{
                        type: 'column',
                        name: 'Submitted (APV)',
                        data: apv,
                    }, {
                        type: 'line',
                        name: 'Apps Submitted',
                        data: apps,
                        yAxis: 1,
                        colorIndex: 2,
                    },
                    {
                        type: 'column',
                        name: 'Placed',
                        data: placed,
                        colorIndex: 4,
                    }
                    /*{
                        type: 'column',
                        name: 'Unique Writers',
                        data: d2,
                        yAxis: 1,
                    },*/

                ],
            },
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
        //Chart.
    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
