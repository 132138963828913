<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <q-comparison-chart v-if="stats.monthlyUniqueWriters && parseFloat(stats.monthlyUniqueWriters.unique_writers) > 0 && !loading" label="<strong>Unique Writers</strong>" :current="parseFloat(stats.monthlyUniqueWriters.unique_writers)" :previous="parseFloat(stats.monthlyUniqueWritersPrevious.unique_writers)" :goal="0" primary-color="#A5D6A6" secondary-color="#d2e8ec" :previous-category="stats.monthlyUniqueWritersPrevious.monthName" :current-category="stats.monthlyUniqueWriters.monthName"></q-comparison-chart>
    </div>
</template>
<script>
import QComparisonChart from '@/components/charts/QComparisonChart.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QStatsUniqueWritersChart",
    props: ['agent', 'startDate', 'endDate', 'statType', 'timePeriod', 'goal'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            stats: {},
            loading: false
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        modified_goal: function() {
            if (this.timePeriod == "week") {
                return Math.round(this.goal / 4)
            }
            return this.goal
        }
    },
    methods: {
        loadData: debounce(function() {
            if (typeof this.agent == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;

            var params = {
                type: typeof this.statType == 'undefined' ? null : this.statType,
                startDate: this.startDate,
                endDate: this.endDate,
                timePeriod: this.timePeriod
            }; //you can add "type" to get a certain type of stats - baseshop or total agency or production
            //gets the stats
            QuilityAPI.getUniqueWriterStats(this.agent.AgentCode, params).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        }, 200),
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.loadData);
        },
        'startDate': function() {
            this.$nextTick(this.loadData);
        },
        'endDate': function() {
            this.$nextTick(this.loadData);
        },
        'timePeriod': function() {
            this.$nextTick(this.loadData);
        }
    },
    components: {
        QComparisonChart
    }
}

</script>
