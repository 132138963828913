<template>
    <div class="scorecard-card">
        <div class="summit-stats">
            <v-progress-linear indeterminate v-if="loading || agent_loading"></v-progress-linear>
            <q-drilldown-agent-data-table id="summitProgressReport" :loading="loading || agent_loading" :title="title" :the-data="the_data"
                                          :headers="headers" @reload="loadData" :allow-drilldown="false" sort-by="StepOrder"
                                          :sort-desc="true" :export-fields="export_fields" :allow-refresh="false">
                <template #append-top>
                    <p></p>
                </template>
                <template #append-bottom>
                </template>
                <template #filters>
                </template>
            </q-drilldown-agent-data-table>
        </div>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDrilldownAgentDataTable from "@/components/datatables/Drilldowns/QDrilldownAgentDataTable";

export default {
    name: "QSummitProgressTable",

    props: {
        title: {
            type: String,
            default: ''
        },
        filters: {
            type: Object,
            default: {
                timePeriod: 'month',
                statType: 'TotalAgency'
            }
        },
        agentCode: {
            type: String
        }
    },

    data() {
        return {
            the_data: null,
            agent: null,
            loading: false,
            agent_loading: false,
            datePeriod: {},
            backup_filters: {},

        }
    },


    computed: {

        headers: function() {
            return [{
                    text: 'Step Title',
                    value: 'StepTitle',
                },
                {
                    text: 'Order',
                    value: 'StepOrder',
                },
                {
                    text: 'Phase',
                    value: 'StepCategory',
                },
                {
                    text: 'Type',
                    value: 'StepType',
                },
                {
                    text: 'Agents Completed',
                    value: 'agent_progress_count',
                },
            ]
        },

        export_fields: function() {
            return [
                'StepTitle', 'StepOrder', 'StepCategory', 'StepType', 'agent_progress_count'
            ];
        }
    },
    methods: {

        loadData: function() {
            this.loading = true

            QuilityAPI.summitAllStepProgress(this.agentCode, this.filters.statType)
                .then(resp => {
                    this.the_data = resp.data
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    return error
                });
        },

    },
    watch: {
        'filters': {
            // watching filters will load the initial data so no need to do it on created()
            deep: true,
            handler: function(newV) {
                this.loadData();
            }
        },
        'agentCode': function() {
            this.loadData();
        }

    },

    components: {
        QDrilldownAgentDataTable,
    }
}
</script>

