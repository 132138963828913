import { render, staticRenderFns } from "./QScoreCardNewWriters.vue?vue&type=template&id=c915868c&scoped=true&"
import script from "./QScoreCardNewWriters.vue?vue&type=script&lang=js&"
export * from "./QScoreCardNewWriters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c915868c",
  null
  
)

export default component.exports