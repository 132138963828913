<template>
    <div>
        <q-score-card key="QScoreCardNewWriters" ref="card" title="New Writers" api-endpoint="new_writers" drill-down-label="New Writers" drill-down-route="Drilldown_NewWriters" card-style="recruiting" :agent-code="agentCode" :filters="filters" v-on:most_current="updateMostCurrent">
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardNewWriters",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {},
    computed: {

    },
    methods: {
        updateMostCurrent: function(most_current) {
            this.$emit('most_current', most_current);
        }
    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
