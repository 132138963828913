<!-- Use as template for other scorecards //-->
<template>
    <div>
        <!--
        Make sure the key is unique.
        api endpoint will be the part after "/api/private/scorecards/..."
        drill down rout is the "NAME" of the route in the router file.
        The style is the css class name for the section of scorecards to customize colors
        agent code will get passed from the view. Should come from url string or default to the current logged in user. 
        filters will come from the view as well... may or may not be used. 
        format can be "Currency" "Percentage" or don't pass anything for int. 
            //-->
        <q-score-card key="QScoreCardHqBirthdays" title="Agent Birthdays" api-endpoint="hq-birthdays" drill-downLabel="Agent Breakdown" drill-down-route="Drilldown_HQ_Birthdays" card-style="hq-stats" :agent-code="agentCode" :filters="filters" :hide-goal="true">
            Note: Shows number of agents promoted to a new Leadership Level in the given timeframe.
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardHqBirthdays",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {},
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}
</script>
<style scoped>
</style>