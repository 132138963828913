<template>
    <div>
        <q-score-card
            key="QScoreCardRecruitingLeads"
            title="Recruiting Leads"
            api-endpoint="leads/recruiting"
            drill-down-route=""
            card-style="leads"
            :agent-code="agentCode"
            :filters="filters"
            @most_current="updateMostCurrent"
        />
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue"

export default {
    name: "QScoreCardRecruitingLeads",
    props: {
        agentCode: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
    },
    methods: {
        updateMostCurrent(most_current) {
            this.$emit("most_current", most_current)
        },
    },
    components: {
        QScoreCard,
    },
}
</script>
<style scoped></style>
