<template>
    <div>
        <q-score-card key="QScoreCardUniqueLeadTakers" title="Unique Lead Takers" api-endpoint="unique_lead_takers" drill-down-route="" card-style="leads" :agent-code="agentCode" :filters="filters">
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardUniqueLeadTakers",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
