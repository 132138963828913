<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <q-comparison-chart-close-ratio v-if="!loading && stats.rollingCloseRatio6weeks" category="6 Weeks Rolling" label=" <strong>Close Ratio</strong>" :current="parseFloat(stats.rollingCloseRatio6weeks[stat_key + '_apps'])" :previous="parseFloat(stats.rollingCloseRatio6weeks[stat_key + '_leads'])" :goal="0" primary-color="#ED561B" secondary-color="#d2e8ec" previous-category="Leads" current-category="Apps"></q-comparison-chart-close-ratio>
    </div>
</template>
<script>
import QComparisonChartCloseRatio from '@/components/charts/QComparisonChartCloseRatio.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QStatsCloseRatioChart",
    props: ['agent', 'startDate', 'endDate', 'statType', 'timePeriod', 'goal', 'statType'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            stats: {},
            loading: false
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        'stat_key': function() {
            if (this.statType == 'Baseshop') {
                return 'base'
            }
            if (this.statType == 'PersonalProduction') {
                return 'agent'
            }
            if (this.statType == 'Master') {
                return 'master'
            }
            return 'master'
        }
    },
    methods: {
        loadData: debounce(function() {
            if (typeof this.agent == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;

            var params = {
                type: this.statType,
                startDate: this.startDate,
                endDate: this.endDate,
                timePeriod: this.timePeriod
            }; //you can add "type" to get a certain type of stats - baseshop or total agency or production

            //gets the stats
            QuilityAPI.getCloseRatioStats(this.agent.AgentCode, params).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        }, 200),
    },
    watch: {
        'agent.AgentCode': function() {
            this.$nextTick(this.loadData);
        },
        'startDate': function() {
            this.$nextTick(this.loadData);
        },
        'endDate': function() {
            this.$nextTick(this.loadData);
        },
        'timePeriod': function() {
            this.$nextTick(this.loadData);
        }
    },
    components: {
        QComparisonChartCloseRatio
    }
}

</script>
