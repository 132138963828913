<template>
    <div>
        <q-score-card key="QScoreCardUniqueBuilders" ref="card" title="Unique Builders" api-endpoint="unique_builders" drill-down-label="Builders" drill-down-route="Drilldown_Builders" card-style="recruiting" :agent-code="agentCode" :filters="filters" v-on:most_current="updateMostCurrent">
            Note: Stat shows how many unique agents have recruited other agents under them within selected time period
        </q-score-card>
    </div>
</template>
<script>
import QScoreCard from "./QScoreCard.vue";

export default {
    name: "QScoreCardUniqueBuilders",
    props: ["agentCode", "filters"],
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {},
    computed: {

    },
    methods: {
        updateMostCurrent: function(most_current) {
            this.$emit('most_current', most_current);
        }
    },
    watch: {

    },
    components: {
        QScoreCard,
    }
}

</script>
<style scoped>
</style>
